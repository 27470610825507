.siteCard {
    margin-bottom: 30px; 
    
    .card-title {
        h4 {
            display:inline-block;
        }

    }

    .actionButtons {
        display: inline-block;

    }

   

}

.float-end {
    float:right !important;
}